import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Table} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';

import {useDispatch, useSelector} from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/Message";
import {listOrders} from '../actions/orderActions';
import moment from "moment/moment";


function OrderListScreen() {

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const orderList = useSelector(state => state.orderList)
    const {loading, error, orders} = orderList

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin


    useEffect(() => {
        if (userInfo && userInfo.is_staff) {
            dispatch(listOrders())
        } else {
            navigate('/login')
        }

    }, [dispatch, navigate, userInfo])


    return (
        <div>
            <h1>Orders</h1>
            {loading
                ? (<Loader/>)
                : error
                    ? (<Message variant='danger'>{error}</Message>)
                    : (
                        <Table striped bordered hover responsive className='table-sm'>
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>USER</th>
                                <th>DATE</th>
                                <th>TOTAL</th>
                                <th>PAID</th>
                                <th>DELIVERED</th>
                                <th></th>
                            </tr>
                            </thead>

                            <tbody>
                            {orders ? (
                                orders.map((order) => (
                                    <tr key={order._id}>
                                        <td>{order.name}</td>
                                        <td>{order.user && order.user.name}</td>
                                        <td>{order.createdAt ?
                                            moment(order.createdAt).format('MMMM Do, YYYY') : 'N/A'}</td>
                                        <td>{order.totalPrice}</td>
                                        <td>{order.isPaid ?
                                            (moment(order.paidAt).format('MMMM Do, YYYY') || 'N/A') :
                                            <i className='fas fa-times' style={{color: 'red'}}></i>
                                        }
                                        </td>

                                        <td>
                                            {order.isDelivered ?
                                                (moment(order.deliveredAt).format('MMMM Do, YYYY') || 'N/A') :
                                                <i className='fas fa-times' style={{color: 'red'}}></i>
                                            }
                                        </td>
                                        <td>
                                            <LinkContainer to={`/order/${order._id}`}>
                                            <Button variant='light' className='btn-sm'>
                                                    Details
                                                </Button>
                                            </LinkContainer>

                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5">Users not found</td>
                                </tr>
                            )}
                            </tbody>
                        </Table>
                    )}
        </div>
    );
}

export default OrderListScreen;