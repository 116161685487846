import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Row, Col, Button, Form, Table} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';

import {useDispatch, useSelector} from "react-redux";
import {getUserDetails, updateUserProfile} from '../actions/userActions';
import Loader from "../components/Loader";
import Message from "../components/Message";
import {USER_UPDATE_PROFILE_RESET} from '../constants/userConstants';
import {listMyOrders} from '../actions/orderActions';


function ProfileScreen() {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');


    const dispatch = useDispatch()

    const userDetails = useSelector(state => state.userDetails)
    const {error, loading, user} = userDetails

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    const userUpdateProfile = useSelector(state => state.userUpdateProfile)
    const {success} = userUpdateProfile

    const orderMyList = useSelector(state => state.orderMyList)
    const {loading: loadingOrders, error: errorOrders, orders} = orderMyList

    useEffect(() => {
        const fetchUserProfile = async () => {
            if (!userInfo) {
                navigate('/login');
            } else {
                if (!user || !user.name || success || userInfo._id !== user._id) {
                    dispatch({type: USER_UPDATE_PROFILE_RESET});
                    await dispatch(getUserDetails('profile'));
                    await dispatch(listMyOrders());
                } else {
                    setName(user.name);
                    setEmail(user.email);
                }
            }
        };

        fetchUserProfile();
    }, [dispatch, navigate, userInfo, user, success]);


    const submitHandler = (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setMessage('Password do not match.')
        } else {
            dispatch(updateUserProfile({
                'id': user._id,
                'name': name,
                'email': email,
                'password': password
            }))
            setMessage('')
        }

    };
    return (
        <Row>
            <Col md={3}>
                <h2>User Profile</h2>

                {message && <Message variant='danger'>{message} </Message>}
                {error && <Message variant='danger'>{error} </Message>}
                {loading && <Loader/>}
                <Form onSubmit={submitHandler}>


                    <Form.Group controlId='name'>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            required
                            type='name'
                            placeholder='Enter Name'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId='email'>
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            required
                            type='email'
                            placeholder='Enter Email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId='password'>
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type='password'
                            placeholder='Enter Password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group controlId='passwordConform'>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                            type='password'
                            placeholder='Confirm Password'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </Form.Group>

                    <Button type='submit' variant='primary'>
                        Update
                    </Button>
                </Form>
            </Col>

            <Col md={9}>
                <h2>My Orders</h2>
                {loadingOrders ? (
                    <Loader/>
                ) : errorOrders ? (
                    <Message variant='danger'>{errorOrders}</Message>
                ) : (
                    <Table striped responsive className='table-sm'>
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Date</th>
                            <th>Total</th>
                            <th>Paid</th>
                            <th>Delivered</th>
                        </tr>
                        </thead>

                        <tbody>
                        {orders && orders.map((order) => (
                            <tr key={order._id}>
                                <td>{order._id}</td>
                                <td>{order.createdAt ? order.createdAt.substring(0, 10) : 'N/A'}</td>
                                <td>{order.totalPrice}</td>
                                <td>{order.isPaid ? (order.paidAt ? order.paidAt.substring(0, 10) : 'N/A') :
                                    <i className='fas fa-times' style={{color: 'red'}}></i>}</td>
                                <td>
                                    <LinkContainer to={`/order/${order._id}`}>
                                        <Button className='btn-sm'>Details</Button>
                                    </LinkContainer>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                )}
            </Col>
        </Row>
    );
}

export default ProfileScreen;